var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.profileMatter)?[_c('h2',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.mattersessionplanning", false, "Planificación de la Asignatura" ))+" ")]),(_vm.profileMatter)?_c('MatterPresentation',{attrs:{"Matter":_vm.profileMatter}}):_vm._e(),(_vm.section && _vm.institution)?_c('Header',{staticClass:"pt-1 pb-4",attrs:{"Matter":_vm.profileMatter,"allows_crud":_vm.institution.actual_period != null &&
        _vm.institution.actual_period.id == _vm.section.period
          ? _vm.allows_crud
          : false,"section_id":_vm.section_id}}):_vm._e(),_c('b-tabs',{attrs:{"content-class":"mt-3"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[(!_vm.user.is_student && _vm.career)?_c('b-tab',{attrs:{"title":`${_vm.$getVisibleNames(
          'teaching.sessionplanning',
          false,
          'Planificación'
        )} Institucional`}},[(_vm.profileMatter && _vm.matter_session_planning)?_c('SessionTable',{attrs:{"key_label":'matter',"allows_crud":(_vm.user_position &&
              [1, 2].includes(_vm.user_position.position) &&
              [1, 2].includes(_vm.user.groups[0])) ||
            _vm.user.is_superuser ||
            _vm.$hasObjectPermission('mesh.faculty', 'change', _vm.career.faculty) ||
            _vm.$hasObjectPermission('mesh.career', 'change', _vm.career.id)
              ? _vm.allows_crud
              : _vm.user.is_professor
              ? false
              : false,"section_id":_vm.section_id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"matter_id":_vm.profileMatter.matter,"filter_list":_vm.matter_session_planning,"DirectTeachingActivities":_vm.direct_teaching_activities,"AutonomousTeachingActivities":_vm.autonomous_teaching_activity,"TeachingSupportResource":_vm.teaching_support_resource,"BibliographicResources":_vm.bibliographic_resources,"EvaluationCriteriaContents":_vm.evaluation_criteria_contents}}):_vm._e()],1):_vm._e(),(_vm.egressProfile && _vm.career && !_vm.user.is_student)?_c('b-tab',{attrs:{"title":`
        ${_vm.$getVisibleNames(
          'teaching.sessionplanning',
          false,
          'Planificación'
        )} para el ${_vm.$getVisibleNames(
          'mesh.egressprofile',
          false,
          'Perfil de Egreso'
        )}
        `}},[(
            _vm.egressProfile.is_closed
              ? false
              : (_vm.user_position &&
                  [1, 2].includes(_vm.user_position.position) &&
                  [1, 2].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.faculty',
                  'change',
                  _vm.career.faculty
                ) ||
                _vm.$hasObjectPermission('mesh.career', 'change', _vm.career.id)
              ? _vm.allows_crud
              : _vm.user.is_professor
              ? false
              : false
          )?_c('b-button',{staticClass:"m-2 button-class",on:{"click":function($event){return _vm.copyPlanning('ep_matter', 'matter')}}},[_vm._v(" Copiar la "+_vm._s(_vm.$getVisibleNames( "teaching.sessionplanning", false, "Planificación" ))+" Institucional")]):_vm._e(),(_vm.profileMatter && _vm.ep_matter_session_planning && _vm.egressProfile)?_c('SessionTable',{key:_vm.keyEPMatter,attrs:{"key_label":'ep_matter',"allows_crud":_vm.egressProfile.is_closed
              ? false
              : (_vm.user_position &&
                  [1, 2].includes(_vm.user_position.position) &&
                  [1, 2].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser ||
                _vm.$hasObjectPermission(
                  'mesh.faculty',
                  'change',
                  _vm.career.faculty
                ) ||
                _vm.$hasObjectPermission('mesh.career', 'change', _vm.career.id)
              ? _vm.allows_crud
              : _vm.user.is_professor
              ? false
              : false,"section_id":_vm.section_id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"filter_list":_vm.ep_matter_session_planning,"DirectTeachingActivities":_vm.direct_teaching_activities,"AutonomousTeachingActivities":_vm.autonomous_teaching_activity,"TeachingSupportResource":_vm.teaching_support_resource,"BibliographicResources":_vm.bibliographic_resources,"EvaluationCriteriaContents":_vm.evaluation_criteria_contents}}):_vm._e()],1):_vm._e(),(_vm.section && _vm.institution)?_c('b-tab',{attrs:{"title":`${_vm.$getVisibleNames(
          'teaching.sectionsessionplanning',
          false,
          'Planificación para la Sección'
        )}`}},[(
            _vm.institution.actual_period != null &&
            _vm.institution.actual_period.id == _vm.section.period
              ? _vm.allows_crud
              : false
          )?_c('b-button',{staticClass:"m-2 button-class",on:{"click":function($event){return _vm.copyPlanning('section', 'matter')}}},[_vm._v(" Copiar la "+_vm._s(_vm.$getVisibleNames( "teaching.sessionplanning", false, "Planificación" ))+" Institucional")]):_vm._e(),(
            _vm.institution.actual_period != null &&
            _vm.institution.actual_period.id == _vm.section.period
              ? _vm.allows_crud
              : false
          )?_c('b-button',{staticClass:"m-2 button-class",on:{"click":function($event){return _vm.copyPlanning('section', 'ep_matter')}}},[_vm._v(" Copiar la "+_vm._s(_vm.$getVisibleNames( "teaching.sessionplanning", false, "Planificación" ))+" del "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")))]):_vm._e(),(_vm.section_id && _vm.section_session_planning)?_c('SessionTable',{key:_vm.keySection,attrs:{"key_label":'section',"allows_crud":_vm.institution.actual_period != null &&
            _vm.institution.actual_period.id == _vm.section.period
              ? _vm.allows_crud
              : false,"section_id":_vm.section_id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"filter_list":_vm.section_session_planning,"DirectTeachingActivities":_vm.direct_teaching_activities,"AutonomousTeachingActivities":_vm.autonomous_teaching_activity,"TeachingSupportResource":_vm.teaching_support_resource,"BibliographicResources":_vm.bibliographic_resources,"EvaluationCriteriaContents":_vm.evaluation_criteria_contents}}):_vm._e()],1):_vm._e()],1),((_vm.user.is_professor || _vm.user.is_student) && _vm.section)?_c('DashboardCustomFab',{staticClass:"noprint",attrs:{"section_id":_vm.section_id,"show_plannification":false}}):_vm._e(),(!_vm.section)?_c('EvaluationCustomFab',{staticClass:"noprint",attrs:{"matter_id":_vm.egress_profile_matter_id,"show_plannification":false}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
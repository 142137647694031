<template>
  <div>
    <template v-if="profileMatter">
      <h2>
        {{
          $getVisibleNames(
            "teaching.mattersessionplanning",
            false,
            "Planificación de la Asignatura"
          )
        }}
      </h2>
      <MatterPresentation
        v-if="profileMatter"
        :Matter="profileMatter"
      ></MatterPresentation>
      <Header
        class="pt-1 pb-4"
        v-if="section && institution"
        :Matter="profileMatter"
        :allows_crud="
          institution.actual_period != null &&
          institution.actual_period.id == section.period
            ? allows_crud
            : false
        "
        :section_id="section_id"
      ></Header>
      <b-tabs content-class="mt-3" v-model="tabIndex">
        <b-tab
          :title="`${$getVisibleNames(
            'teaching.sessionplanning',
            false,
            'Planificación'
          )} Institucional`"
          v-if="!user.is_student && career"
        >
          <SessionTable
            v-if="profileMatter && matter_session_planning"
            :key_label="'matter'"
            :allows_crud="
              (user_position &&
                [1, 2].includes(user_position.position) &&
                [1, 2].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission('mesh.faculty', 'change', career.faculty) ||
              $hasObjectPermission('mesh.career', 'change', career.id)
                ? allows_crud
                : user.is_professor
                ? false
                : false
            "
            :section_id="section_id"
            :egress_profile_matter_id="egress_profile_matter_id"
            :matter_id="profileMatter.matter"
            :filter_list="matter_session_planning"
            :DirectTeachingActivities="direct_teaching_activities"
            :AutonomousTeachingActivities="autonomous_teaching_activity"
            :TeachingSupportResource="teaching_support_resource"
            :BibliographicResources="bibliographic_resources"
            :EvaluationCriteriaContents="evaluation_criteria_contents"
          ></SessionTable>
        </b-tab>
        <b-tab
          :title="`
          ${$getVisibleNames(
            'teaching.sessionplanning',
            false,
            'Planificación'
          )} para el ${$getVisibleNames(
            'mesh.egressprofile',
            false,
            'Perfil de Egreso'
          )}
          `"
          v-if="egressProfile && career && !user.is_student"
        >
          <b-button
            class="m-2 button-class"
            @click="copyPlanning('ep_matter', 'matter')"
            v-if="
              egressProfile.is_closed
                ? false
                : (user_position &&
                    [1, 2].includes(user_position.position) &&
                    [1, 2].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.faculty',
                    'change',
                    career.faculty
                  ) ||
                  $hasObjectPermission('mesh.career', 'change', career.id)
                ? allows_crud
                : user.is_professor
                ? false
                : false
            "
          >
            Copiar la
            {{
              $getVisibleNames(
                "teaching.sessionplanning",
                false,
                "Planificación"
              )
            }}
            Institucional</b-button
          >
          <SessionTable
            v-if="profileMatter && ep_matter_session_planning && egressProfile"
            :key="keyEPMatter"
            :key_label="'ep_matter'"
            :allows_crud="
              egressProfile.is_closed
                ? false
                : (user_position &&
                    [1, 2].includes(user_position.position) &&
                    [1, 2].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.faculty',
                    'change',
                    career.faculty
                  ) ||
                  $hasObjectPermission('mesh.career', 'change', career.id)
                ? allows_crud
                : user.is_professor
                ? false
                : false
            "
            :section_id="section_id"
            :egress_profile_matter_id="egress_profile_matter_id"
            :filter_list="ep_matter_session_planning"
            :DirectTeachingActivities="direct_teaching_activities"
            :AutonomousTeachingActivities="autonomous_teaching_activity"
            :TeachingSupportResource="teaching_support_resource"
            :BibliographicResources="bibliographic_resources"
            :EvaluationCriteriaContents="evaluation_criteria_contents"
          ></SessionTable>
        </b-tab>
        <b-tab
          v-if="section && institution"
          :title="`${$getVisibleNames(
            'teaching.sectionsessionplanning',
            false,
            'Planificación para la Sección'
          )}`"
        >
          <b-button
            class="m-2 button-class"
            @click="copyPlanning('section', 'matter')"
            v-if="
              institution.actual_period != null &&
              institution.actual_period.id == section.period
                ? allows_crud
                : false
            "
          >
            Copiar la
            {{
              $getVisibleNames(
                "teaching.sessionplanning",
                false,
                "Planificación"
              )
            }}
            Institucional</b-button
          >
          <b-button
            class="m-2 button-class"
            @click="copyPlanning('section', 'ep_matter')"
            v-if="
              institution.actual_period != null &&
              institution.actual_period.id == section.period
                ? allows_crud
                : false
            "
          >
            Copiar la
            {{
              $getVisibleNames(
                "teaching.sessionplanning",
                false,
                "Planificación"
              )
            }}
            del
            {{
              $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")
            }}</b-button
          >
          <SessionTable
            v-if="section_id && section_session_planning"
            :key="keySection"
            :key_label="'section'"
            :allows_crud="
              institution.actual_period != null &&
              institution.actual_period.id == section.period
                ? allows_crud
                : false
            "
            :section_id="section_id"
            :egress_profile_matter_id="egress_profile_matter_id"
            :filter_list="section_session_planning"
            :DirectTeachingActivities="direct_teaching_activities"
            :AutonomousTeachingActivities="autonomous_teaching_activity"
            :TeachingSupportResource="teaching_support_resource"
            :BibliographicResources="bibliographic_resources"
            :EvaluationCriteriaContents="evaluation_criteria_contents"
          ></SessionTable>
        </b-tab>
      </b-tabs>

      <!-- Navegación -->
      <DashboardCustomFab
        v-if="(user.is_professor || user.is_student) && section"
        class="noprint"
        :section_id="section_id"
        :show_plannification="false"
      ></DashboardCustomFab>
      <EvaluationCustomFab
        v-if="!section"
        class="noprint"
        :matter_id="egress_profile_matter_id"
        :show_plannification="false"
      ></EvaluationCustomFab>
    </template>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "SessionPlanningView",
  components: {
    Header: () => import("@/components/teaching/Planification/Header"),
    DashboardCustomFab: () =>
      import("@/components/dashboard/DashboardCustomFab"),
    SessionTable: () =>
      import("@/components/teaching/Planification/SessionTable"),
    MatterPresentation: () =>
      import("@/components/teaching/MatterProgram/MatterPresentation"),
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      tabIndex: 0,
      tabControl: 0,
      section_id: Number(this.$route.params.section_id),
      matter_session_planning: null,
      ep_matter_session_planning: null,
      section_session_planning: null,
      direct_teaching_activities: [],
      autonomous_teaching_activity: [],
      teaching_support_resource: [],
      bibliographic_resources: [],
      evaluation_criteria_contents: [],
      keySection: 0,
      keyEPMatter: 0,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      institution: "getInstitution",
      user: "getUser",
      user_position: names.USER_POSITION,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "teaching.change_sessionplanning"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    section() {
      if (!this.section_id) return null;
      return this.sections.find((x) => x.id == this.section_id);
    },
    profileMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    egressProfile() {
      if (!this.profileMatter) return [];
      return this.egressProfiles.find(
        (x) => x.id == this.profileMatter.egress_profile
      );
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
  },
  methods: {
    copyPlanning(key_label, copy_from) {
      if (key_label == "section" && copy_from == "matter")
        this.$restful
          .Post(
            `/teaching/copy_session_planning/?copy_from=matter&section=${this.section_id}`
          )
          .then(() => {
            this.$restful
              .Get(
                `/teaching/section-session-planning/?section=${this.section_id}`
              )
              .then((session_planning) => {
                this.section_session_planning = session_planning;
                this.keySection += 1;
              });
          });
      if (key_label == "section" && copy_from == "ep_matter")
        this.$restful
          .Post(
            `/teaching/copy_session_planning/?copy_from=ep_matter&section=${this.section_id}`
          )
          .then(() => {
            this.$restful
              .Get(
                `/teaching/section-session-planning/?section=${this.section_id}`
              )
              .then((session_planning) => {
                this.section_session_planning = session_planning;
                this.keySection += 1;
              });
          });
      if (key_label == "ep_matter" && copy_from == "matter")
        this.$restful
          .Post(
            `/teaching/copy_session_planning/?copy_from=matter&ep_matter=${this.egress_profile_matter_id}`
          )
          .then(() => {
            this.$restful
              .Get(
                `/teaching/ep-matter-session-planning/?egress_profile_matter=${this.egress_profile_matter_id}`
              )
              .then((session_planning) => {
                this.ep_matter_session_planning = session_planning;
                this.keyEPMatter += 1;
              });
          });
    },
  },
  watch: {
    tabControl: function () {
      if (this.section_id != 0 && this.section_id != null) {
        if (this.tabControl == 3) {
          if (
            this.matter_session_planning.length <
            this.ep_matter_session_planning.length
          )
            this.tabIndex = 1;
          if (
            this.user.is_professor &
            (this.matter_session_planning.length <
              this.section_session_planning.length)
          )
            this.tabIndex = 2;
          if (
            this.user.is_professor &&
            this.ep_matter_session_planning.length >
              this.section_session_planning.length
          )
            this.tabIndex = 1;
        }
        if (this.user.is_student) {
          this.tabIndex = 3;
        }
      } else if (this.tabControl == 2) {
        if (
          this.matter_session_planning.length <
          this.ep_matter_session_planning.length
        )
          this.tabIndex = 1;
      }
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(names.FETCH_METHODOLOGICAL_STRATEGIES);
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$restful.Get(`/mesh/direct-teaching-activity/`).then((response) => {
      this.direct_teaching_activities = response.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    this.$restful
      .Get(`/mesh/autonomous-teaching-activity/`)
      .then((response) => {
        this.autonomous_teaching_activity = response.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((matter) => {
        this.$restful
          .Get(
            `/teaching/evaluation-criteria-macro-content/?matter=${matter.matter}`
          )
          .then((response) => {
            this.evaluation_criteria_contents = response;
          });
        this.$store.dispatch(
          names.FETCH_EVALUATION_CRITERIAS_MACROS,
          matter.matter
        );
        this.$store.dispatch(names.FETCH_EGRESS_PROFILE, matter.egress_profile);
        this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
          egress_profile_id: matter.egress_profile,
        });
        this.$store.dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
          egress_profile_id: matter.egress_profile,
        });
        this.$store.dispatch(names.FETCH_MATTER, matter.matter);
        this.$store
          .dispatch(names.FETCH_STUDY_UNITS, matter.matter)
          .then((study_units) => {
            this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
              matter_id: matter.matter,
            });
            study_units.forEach((study_unit) => {
              this.$store.dispatch(names.FETCH_CONTENTS, study_unit.id);
            });
          });
        this.$restful
          .Get(`/teaching/support-resource/?matter=${matter.matter}`)
          .then((response) => {
            this.teaching_support_resource = response;
          });
        this.$restful
          .Get(`/teaching/bibliographic-resource/?matter=${matter.matter}`)
          .then((response) => {
            this.bibliographic_resources = response;
          });
        this.$restful
          .Get(`/teaching/matter-session-planning/?matter=${matter.matter}`)
          .then((session_planning) => {
            this.matter_session_planning = session_planning;
            this.tabControl += 1;
          });
        this.$restful
          .Get(
            `/teaching/ep-matter-session-planning/?egress_profile_matter=${matter.id}`
          )
          .then((session_planning) => {
            this.ep_matter_session_planning = session_planning;
            this.tabControl += 1;
          });
      });
    if (this.section_id != 0 && this.section_id != null)
      this.$store
        .dispatch(names.FETCH_SECTION, this.section_id)
        .then((section) => {
          this.$restful
            .Get(`/teaching/section-session-planning/?section=${section.id}`)
            .then((session_planning) => {
              this.section_session_planning = session_planning;
              this.tabControl += 1;
            });
        });
  },
};
</script>
<style scoped>
.button-class {
  float: left;
}
</style>